import React, { Suspense } from 'react';
import Head from 'next/head';
import MenuPC from './home/MenuPC';
import Footer from './home/Footer';
import { Layout } from 'antd';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import to from 'await-to-js'
import { ScrollToTopButton } from 'themes/components/Utils'
import useBaseHook from 'themes/hooks/BaseHooks'

// import style from 'themes/scss/default.scss'
const HomeLayout = (props: any) => {
  const { t } = useBaseHook();
  const { metaData = {}, data = {} } = props
    return (<React.Fragment>
      <Head>
        <title>{t('home.general.title')}</title>
        <link rel="canonical" href="http://dohu.vn"/>
        <meta name="description" content={t('home.general.description')} />
        <meta property="og:url" content={`${data.siteUrl}${metaData.url || ""}`} />
        <meta property="og:type" content={metaData.type || "website"} />
        <meta property="og:title" content={metaData.title || data.siteTitle} />
        <meta property="og:description" content={t('home.general.description')} />
        <meta property="og:image" content={metaData.image || data.logo} /> 

        {/* <title>{metaData.title || data.siteTitle || ''}</title>
        <meta property="og:url" content={`${data.siteUrl}${metaData.url || ""}`} />
        <meta property="og:type" content={metaData.type || "website"} />
        <meta property="og:title" content={metaData.title || data.siteTitle} />
        <meta property="og:description" content={metaData.description || data.siteDescription} />
        <meta property="og:image" content={metaData.image || data.logo} /> */}

      </Head>
      <div id="home">
        <main>
          <Layout>
            <MenuPC />
            <div className="content">
              {props.children}
            </div>
            <Footer />
            <ScrollToTopButton />
          </Layout>
        </main>
      </div>
    </React.Fragment>
    );
  }


export default HomeLayout;