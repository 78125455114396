import {useEffect} from 'react'

const Scroll = (onScroll: Function) => {
    const _onScroll = () => {
        let yOffset = window.pageYOffset
        let xOffset = window.pageXOffset
        onScroll({xOffset, yOffset})
    }
    useEffect(() => {
        if(!process.browser) return;
        window.addEventListener("scroll", _onScroll);
        return () => {
            window.addEventListener("scroll", _onScroll);
        }
    })
}

export default Scroll