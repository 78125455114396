import { useState } from 'react'
import { Layout, Menu, Row, Col, Button, Drawer } from 'antd';
const { Header } = Layout;
import { route as makeUrl } from 'themes/route'
import Link from 'next/link'
import useBaseHooks from 'themes/hooks/BaseHooks';
import { i18n } from 'themes/modules/I18n'


const MenuMobie = (props: any) => {
  const { t } = useBaseHooks({ lang: ['menu'] })
  const [visibleSidebar, setVisibleSidebar] = useState(false)
  const [lang, setLang] = useState('vi')
  const onVisibleChange = (newState: boolean) => {
    setVisibleSidebar(newState)
  }

  const changeLanguage = (lang: any) => {
    i18n.changeLanguage(lang)
    setLang('en')
    // location.reload();
  }
  return <>
    <div className="MenuMobie">
      <Drawer visible={props.visible} placement="left" onClose={() => props.onVisibleChange(false)}
        maskClosable
        closable={false}
        destroyOnClose={true}
        bodyStyle={{
          padding: 0,
          marginTop: "70px"
        }}
      >
        <Menu className="TopMenuMobie">
          <Menu.Item key="1">
            <Link href="#bannerHoverChange">{t('common:home.menu.platform')}</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link {...makeUrl("frontend.home.campaign")}>{t('common:home.menu.targetCustomer')}</Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link {...makeUrl("frontend.home.location")}>{t('common:home.menu.inventory')}</Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link {...makeUrl("frontend.home.blog")}>{t('common:home.menu.blog')}</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link href="#contactForm"><a>{t('common:home.menu.contactUs')}</a></Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link href="#contactForm"><a>{t('common:home.menu.owner')}</a></Link>
          </Menu.Item>
          <Menu.Item key="8">
            <Link {...makeUrl("frontend.buyer.login")}><Button className="butnLogin">{t('common:home.menu.login')}</Button></Link>
          </Menu.Item>
          {/* <Menu.Item key="9">
            <Link {...makeUrl("frontend.buyer.login")}><Button className="butnLogin">REGISTER</Button></Link>
          </Menu.Item> */}
          <a>
            <img onClick={() => changeLanguage('vi')}
              src="/static/img/meaning-of-vietnam-flag.jpg" alt="dohu.vn" className="IconLang"
            />
          </a>
          <a>
            <img
              onClick={() => changeLanguage('en')}
              src="/static/img/Flag_of_the_United_Kingdom.svg" alt="dohu.vn" className="IconLang"
            />
          </a>
        </Menu>
      </Drawer>
    </div>
  </>
}

export default MenuMobie