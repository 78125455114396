import React from "react";
import ScrollUpButton from "react-scroll-up-button"; 
 
export default class Index extends React.Component {
    render() {
        return (
            <div>
                <ScrollUpButton ContainerClassName="scrollUpButton">
                    <div><img src="/static/img/top.svg" alt="dohu.vn"/></div>
                </ScrollUpButton>
            </div>
        );
    }
}